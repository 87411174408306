@charset "UTF-8";
#contact {
  -webkit-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 70vw;
  margin: 15px; }

#mapDiv {
  height: 400px; }

#dataDiv {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.showButton {
  border: none;
  background: none;
  font-size: 1em;
  font-family: 'Imprima', sans-serif;
  color: #74479b; }

.showButton:hover {
  color: #5df0ab;
  cursor: pointer; }

#alert {
  color: red;
  margin: 0.8vw;
  font-size: 26px; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 960px) {
  #contact {
    width: 90vw; }
  #mapDiv {
    height: 300px; }
  #alert {
    font-size: 18px; } }
