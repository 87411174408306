body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@charset "UTF-8";
.headerDiv {
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 960px;
  margin: 15px; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 756px) {
  .headerDiv {
    width: 95%; }
  .bannerImage {
    width: 95%; } }

/*
     Style działające dla szerokości
     ekranu większej od 60em
   */

@charset "UTF-8";
.menuDiv {
  display: flex;
  width: 65vw;
  height: 50px;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  margin: 15px; }

.listElement {
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  width: 22%;
  background-color: #74479b;
  height: 40px;
  color: black;
  font-size: 25px;
  color: white;
  cursor: pointer; }

.listElement:hover {
  background-color: #5df0ab; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 756px) {
  .listElement {
    font-size: 15px;
    height: 28px;
    width: 28%; } }

/*
     Style działające dla szerokości
     ekranu większej od 60em
   */
@media screen and (min-width: 60em) {
  .listElement {
    font-size: 30px; } }

@charset "UTF-8";
#about {
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 70vw;
  margin: 15px; }

.workPhoto {
  width: 100%;
  margin-bottom: -7px;
  box-sizing: border-box; }

.banner-user-elem {
  width: 100%;
  box-sizing: border-box;
  position: static !important; }

.banner-anim-arrow {
  background: #74479b !important; }

.banner-anim-thumb span {
  background: white !important; }

.banner-anim-thumb .active {
  background: #74479b !important; }

.active {
  background: #74479b !important; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 960px) {
  #about {
    width: 90vw; } }

@charset "UTF-8";
#offer {
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 70vw;
  margin: 15px;
  display: flex;
  flex-direction: column; }

#offerList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 50px;
  margin-top: 30px;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px; }

.service {
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  font-size: 25px;
  width: 40%;
  min-height: 500px;
  padding-bottom: 40px;
  padding-right: 15px;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center; }

.offerItem {
  margin-top: 15px;
  width: 100%; }

.serviceImage {
  width: 120px;
  margin: 20px; }

.offers {
  font-size: 16px;
  text-decoration: none;
  width: 85%;
  margin: 2px;
  text-align: left; }

.offerBig {
  color: #74479b;
  font-weight: bold; }

@media screen and (max-width: 960px) {
  #offer {
    width: 90vw;
    margin: 5px; }
  #offerList {
    flex-direction: column;
    width: 100%;
    align-items: center; }
  .service {
    font-size: 20px;
    min-height: unset;
    width: 90%;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px; }
  .offers {
    width: 80%; }
  .serviceImage {
    width: 80px; } }

/*
     Style działające dla szerokości
     ekranu większej od 60em
   */

@charset "UTF-8";
#contact {
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 70vw;
  margin: 15px; }

#mapDiv {
  height: 400px; }

#dataDiv {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.showButton {
  border: none;
  background: none;
  font-size: 1em;
  font-family: 'Imprima', sans-serif;
  color: #74479b; }

.showButton:hover {
  color: #5df0ab;
  cursor: pointer; }

#alert {
  color: red;
  margin: 0.8vw;
  font-size: 26px; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 960px) {
  #contact {
    width: 90vw; }
  #mapDiv {
    height: 300px; }
  #alert {
    font-size: 18px; } }

.footerDiv {
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 99vw;
  box-sizing: border-box;
  background-color: #74479b;
  color: white;
  display: flex;
  padding: 1vw;
  flex-direction: row;
  justify-content: space-around; }

#authorDiv {
  font-size: 10px;
  margin: 0;
  align-self: flex-end; }

#iconsDiv {
  margin: 0.5vw; }

a {
  color: black; }

.slideout-widget {
  position: fixed;
  right: -6.4em;
  top: 30%;
  transition: right .4s;
  width: 7em;
  z-index: 20; }

.slideout-widget:hover {
  right: 5em; }

.slideout-widget-handler,
.slideout-widget-content {
  background: none;
  border: none;
  padding: 1em .5em; }

.slideout-widget-handler {
  border-right: none;
  left: -3.5em;
  position: absolute;
  text-align: center;
  width: 1em; }

.slideout-widget-content {
  border-width: 1px 0;
  overflow: hidden;
  z-index: 15;
  width: 7em; }

.widget-facebook .slideout-widget-handler,
.widget-facebook .slideout-widget-content {
  font-size: 25px; }

.fbimage {
  width: 70px; }

.widget-facebook,
.widget-facebook a {
  color: #3c5b9b;
  text-decoration: none; }

.widget-facebook a:hover {
  color: #5df0ab; }

@media (max-width: 768px) {
  .slideout-widget {
    display: none; } }

@charset "UTF-8";
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }

body {
  font-family: 'Imprima', sans-serif; }

.dataDiv {
  font-size: 20px;
  padding: 5px;
  margin: 10px; }

.heading {
  font-size: 35px;
  margin: 10px;
  color: #74479b;
  font-weight: bold; }

.subHeader {
  font-size: 27px;
  margin: 10px;
  color: #74479b;
  font-weight: bold; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 756px) {
  .dataDiv {
    font-size: 18px;
    padding: 5px; }
  .heading {
    font-size: 28px;
    margin: 5px; }
  .subHeader {
    font-size: 23px;
    margin: 5px; } }

