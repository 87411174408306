@charset "UTF-8";
.menuDiv {
  display: flex;
  width: 65vw;
  height: 50px;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  margin: 15px; }

.listElement {
  -webkit-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  width: 22%;
  background-color: #74479b;
  height: 40px;
  color: black;
  font-size: 25px;
  color: white;
  cursor: pointer; }

.listElement:hover {
  background-color: #5df0ab; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 756px) {
  .listElement {
    font-size: 15px;
    height: 28px;
    width: 28%; } }

/*
     Style działające dla szerokości
     ekranu większej od 60em
   */
@media screen and (min-width: 60em) {
  .listElement {
    font-size: 30px; } }
