// ----- kolory aplikacji -----

$violet: #74479b;
$green: rgb(93, 240, 171);

// ----- wielkosci czcionek -----

$informationFont: 20px;
$informationFontMobile: 18px;

$headerFont: 35px;
$headerFontMobile: 28px;

$subHeaderFont: 27px;
$subHeaderFontMobile: 23px;

$md : 960px;
$sm : 756px;

// ----- cienie -----

@mixin shadows() {
    -webkit-box-shadow: 4px 5px 15px 3px rgba(0,0,0,0.15);
   -moz-box-shadow: 4px 5px 15px 3px rgba(0,0,0,0.15);
   box-shadow: 4px 5px 15px 3px rgba(0,0,0,0.15);
  }

// ----- inne -----

@mixin parentDivs(){
    @include shadows();
    width: 70vw;
    margin: 15px;
}