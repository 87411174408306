@import '../Variables';

.slideout-widget {
 position: fixed;
 right: -6.4em;
 top: 30%;
 transition: right .4s;
 width: 7em;
 z-index: 20;
}
 
.slideout-widget:hover {
 right: 5em;
}
 
.slideout-widget-handler,
.slideout-widget-content {
 background: none;
 border: none;
 padding: 1em .5em;
}
 
.slideout-widget-handler {
 border-right: none;
 left: -3.5em;
 position: absolute;
 text-align: center;
 width: 1em;
 
}
.slideout-widget-content {
 border-width: 1px 0;
 overflow: hidden;
 z-index: 15;
 width: 7em;
}

.widget-facebook .slideout-widget-handler,
.widget-facebook .slideout-widget-content {
    font-size: 25px;

}
.fbimage{
    width: 70px;
}

 
.widget-facebook,
.widget-facebook a {
 color:#3c5b9b;
 text-decoration: none;
}
.widget-facebook a:hover {
    color:$green;

   }

@media (max-width: 768px) {
 .slideout-widget {
 display: none;
 }
}