@charset "UTF-8";
.headerDiv {
  -webkit-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 960px;
  margin: 15px; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 756px) {
  .headerDiv {
    width: 95%; }
  .bannerImage {
    width: 95%; } }

/*
     Style działające dla szerokości
     ekranu większej od 60em
   */
