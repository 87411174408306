@charset "UTF-8";
#about {
  -webkit-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 70vw;
  margin: 15px; }

.workPhoto {
  width: 100%;
  margin-bottom: -7px;
  box-sizing: border-box; }

.banner-user-elem {
  width: 100%;
  box-sizing: border-box;
  position: static !important; }

.banner-anim-arrow {
  background: #74479b !important; }

.banner-anim-thumb span {
  background: white !important; }

.banner-anim-thumb .active {
  background: #74479b !important; }

.active {
  background: #74479b !important; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 960px) {
  #about {
    width: 90vw; } }
