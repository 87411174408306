@charset "UTF-8";
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }

body {
  font-family: 'Imprima', sans-serif; }

.dataDiv {
  font-size: 20px;
  padding: 5px;
  margin: 10px; }

.heading {
  font-size: 35px;
  margin: 10px;
  color: #74479b;
  font-weight: bold; }

.subHeader {
  font-size: 27px;
  margin: 10px;
  color: #74479b;
  font-weight: bold; }

/*
  Style działające dla szerokości
  ekranu mniejszej od 768px
*/
@media screen and (max-width: 756px) {
  .dataDiv {
    font-size: 18px;
    padding: 5px; }
  .heading {
    font-size: 28px;
    margin: 5px; }
  .subHeader {
    font-size: 23px;
    margin: 5px; } }
