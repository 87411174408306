@import '../Variables';
.footerDiv{
    @include shadows();
    width: 99vw;
    box-sizing: border-box;
    background-color: $violet;
    color: white;
    display: flex;
    padding: 1vw;
    flex-direction: row;
    justify-content: space-around;
}

#authorDiv{
    font-size: 10px;
    margin: 0;
    align-self: flex-end;
}
#iconsDiv{
    margin: 0.5vw;
}
a{
    color: black
}