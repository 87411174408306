@charset "UTF-8";
#offer {
  -webkit-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  width: 70vw;
  margin: 15px;
  display: flex;
  flex-direction: column; }

#offerList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 50px;
  margin-top: 30px;
  gap: 20px;
  padding: 20px; }

.service {
  -webkit-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 5px 15px 3px rgba(0, 0, 0, 0.15);
  font-size: 25px;
  width: 40%;
  min-height: 500px;
  padding-bottom: 40px;
  padding-right: 15px;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center; }

.offerItem {
  margin-top: 15px;
  width: 100%; }

.serviceImage {
  width: 120px;
  margin: 20px; }

.offers {
  font-size: 16px;
  text-decoration: none;
  width: 85%;
  margin: 2px;
  text-align: left; }

.offerBig {
  color: #74479b;
  font-weight: bold; }

@media screen and (max-width: 960px) {
  #offer {
    width: 90vw;
    margin: 5px; }
  #offerList {
    flex-direction: column;
    width: 100%;
    align-items: center; }
  .service {
    font-size: 20px;
    min-height: unset;
    width: 90%;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px; }
  .offers {
    width: 80%; }
  .serviceImage {
    width: 80px; } }

/*
     Style działające dla szerokości
     ekranu większej od 60em
   */
